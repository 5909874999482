import React from "react"
import {
  Navbar,
  NavList,
  NavListItem,
  NavTitle,
  NavToggle,
  StyledButton,
  Footer,
  FooterLink,
} from "../components"

import { navigate } from "gatsby"
import scrollTo from "gatsby-plugin-smoothscroll"
import { Menu } from "../assets"
import styled from "styled-components"

const NavButton = styled(StyledButton)`
  @media screen and (max-width: 1550px) {
    display: none;
  }
`

interface Props {
  children: React.ReactNode
}

const Layout = ({ children }: Props) => {
  return (
    <>
      <header>
        <Navbar
          fixed
          background="transparent"
          secondary="var(--gradientdark)"
          scrollColor="#fff"
        >
          <NavTitle />
          <NavToggle svg={Menu} />

          <NavList background="var(--gradientdark)">
            <NavListItem path="/" router>
              Home
            </NavListItem>
            <NavListItem path="/#services" router>
              Services
            </NavListItem>
            <NavListItem path="/Projects" router>
              Projects
            </NavListItem>
            <NavListItem path="/Publications" router>
              Publications
            </NavListItem>
            <NavListItem path="/Press-Releases" router>
              Press Releases
            </NavListItem>
            <NavListItem path="/About" router>
              About Us
            </NavListItem>
            <NavListItem btn>Contact Us</NavListItem>
          </NavList>
          <NavButton
            background="transparent"
            border="var(--primary)"
            color="var(--primary)"
            altcolor="#fff"
            onClick={() => {
              window.location.pathname !== "/"
                ? navigate("/#contact")
                : scrollTo("#contact")
            }}
          >
            Contact Us
          </NavButton>
        </Navbar>
      </header>
      <div style={{ display: "flex", flexDirection: "column" }}>{children}</div>

      <Footer>
        <FooterLink path="/#hero">Home</FooterLink>
        <FooterLink path="/#services">Services</FooterLink>
        <FooterLink path="/Projects">Projects</FooterLink>
        <FooterLink path="/Publications">Publications</FooterLink>
        <FooterLink path="/Press-Releases">Press Releases</FooterLink>
        <FooterLink path="/About">Abouts Us</FooterLink>
        <FooterLink path="/#contact">Contact</FooterLink>
      </Footer>
    </>
  )
}

export default Layout
