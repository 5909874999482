import React from "react"
import { SEO } from "../components"
import Layout from "../layouts"
import { AboutCard } from "../components"
import { StyledGrid, StyledMainHeader } from "../styles/pageStyles"

const About = (): JSX.Element => {
  return (
    <>
      <SEO title="About" />
      <Layout>
        <StyledGrid>
          <StyledMainHeader>About Us</StyledMainHeader>
          <AboutCard />
        </StyledGrid>
      </Layout>
    </>
  )
}

export default About
